//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseImage',
  props: {
    sourceSet: {
      type: Array,
      default: () => [],
      validator: (images) =>
        images.every(
          (image) => 'url' in image && 'type' in image && 'breakpoint' in image,
        ),
    },
    url: {
      type: String,
      default: '',
    },
    alternativeText: {
      type: String,
      default: '',
    },
    isBackground: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    fallbackImage: null,
  }),
  mounted() {
    if (this.url) {
      return (this.fallbackImage = this.url);
    } else if (this.sourceSet?.length) {
      const screenWidth = window.screen.width;

      const supportedImage = this.sourceSet.find(
        (src) => !src.type.includes('webp'),
      );
      if (!supportedImage) {
        throw new Error('Please set images not only webp format');
      }

      const selectedImage = { ...supportedImage, breakpoint: 0 };
      const matchedImage = this.sourceSet.reduce((acc, source) => {
        if (!source.type.includes('webp')) {
          const totalSize = screenWidth - parseInt(source.breakpoint);
          if (
            totalSize >= 0 &&
            parseInt(source.breakpoint) > parseInt(acc.breakpoint)
          ) {
            acc = source;
          }
        }
        return acc;
      }, selectedImage);
      return (this.fallbackImage = matchedImage.url);
    } else {
      throw new Error('Please set image');
    }
  },
};
