//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import BaseLink from '@/components/atoms/base-link/BaseLink.vue';
import CopyrightLabel from '@/components/atoms/copyright-label/CopyrightLabel.vue';
import CookieBanner from '~/components/molecules/cookie-banner/CookieBanner';

export default {
  name: 'FooterDefault',
  components: {
    BaseLink,
    CopyrightLabel,
    CookieBanner,
  },

  props: {
    footerNavigation: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('cookie', ['hasConsented']),
  },
  mounted() {
    this.$store.dispatch('cookie/setStoredCookieConsent');
  },
};
