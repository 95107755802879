// @ts-check

/**
 * Hyphenated to UpperCamelCase
 *
 * @param {String} str
 * @returns {String}
 */
export function toClassName(str) {
  return toCapitalCase(toPropertyName(str));
}

/**
 * Capitalizes the first letter of a string
 *
 * @param {String} s
 * @returns {String}
 */
export function toCapitalCase(s) {
  return s.charAt(0).toUpperCase() + s.substr(1);
}

/**
 * Hyphenated to lowerCamelCase
 *
 * @param {String} s
 * @returns {String}
 */
export function toPropertyName(s) {
  return s
    .replace(/([^a-zA-Z0-9])+(.)?/g, (_, __, chr) =>
      chr ? chr.toUpperCase() : '',
    )
    .replace(/[^a-zA-Z\d]/g, '')
    .replace(/^([A-Z])/, (m) => m.toLowerCase());
}
