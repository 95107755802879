export const state = () => ({
  siteBannerHeight: 0,
  headerHeight: 80,
  pageTopOffset: 0,
});

export const mutations = {
  setSiteBannerHeight(state, payload) {
    state.siteBannerHeight = payload.height;
    state.pageTopOffset = state.headerHeight + payload.height;
  },
  setHeaderHeight(state, payload) {
    state.headerHeight = payload.height;
    state.pageTopOffset = state.siteBannerHeight + payload.height;
  },
};
