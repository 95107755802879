import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  RenderComponent: () => import('../../components/RenderComponent.vue' /* webpackChunkName: "components/render-component" */).then(c => wrapFunctional(c.default || c)),
  Accordion: () => import('../../components/atoms/accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c)),
  AccordionItem: () => import('../../components/atoms/accordion/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c)),
  BaseButton: () => import('../../components/atoms/base-button/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c)),
  BaseCheckbox: () => import('../../components/atoms/base-checkbox/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c)),
  BaseHeading: () => import('../../components/atoms/base-heading/BaseHeading.vue' /* webpackChunkName: "components/base-heading" */).then(c => wrapFunctional(c.default || c)),
  BaseIcon: () => import('../../components/atoms/base-icon/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c)),
  BaseImage: () => import('../../components/atoms/base-image/BaseImage.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c)),
  BaseInput: () => import('../../components/atoms/base-input/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c)),
  BaseLink: () => import('../../components/atoms/base-link/BaseLink.vue' /* webpackChunkName: "components/base-link" */).then(c => wrapFunctional(c.default || c)),
  BaseList: () => import('../../components/atoms/base-list/BaseList.vue' /* webpackChunkName: "components/base-list" */).then(c => wrapFunctional(c.default || c)),
  BaseLoading: () => import('../../components/atoms/base-loading/BaseLoading.vue' /* webpackChunkName: "components/base-loading" */).then(c => wrapFunctional(c.default || c)),
  BaseSelectField: () => import('../../components/atoms/base-select-field/BaseSelectField.vue' /* webpackChunkName: "components/base-select-field" */).then(c => wrapFunctional(c.default || c)),
  BaseSubheading: () => import('../../components/atoms/base-subheading/BaseSubheading.vue' /* webpackChunkName: "components/base-subheading" */).then(c => wrapFunctional(c.default || c)),
  BaseTable: () => import('../../components/atoms/base-table/BaseTable.vue' /* webpackChunkName: "components/base-table" */).then(c => wrapFunctional(c.default || c)),
  BaseTag: () => import('../../components/atoms/base-tag/BaseTag.vue' /* webpackChunkName: "components/base-tag" */).then(c => wrapFunctional(c.default || c)),
  BaseText: () => import('../../components/atoms/base-text/BaseText.vue' /* webpackChunkName: "components/base-text" */).then(c => wrapFunctional(c.default || c)),
  BrandLogo: () => import('../../components/atoms/brand-logo/BrandLogo.vue' /* webpackChunkName: "components/brand-logo" */).then(c => wrapFunctional(c.default || c)),
  Container: () => import('../../components/atoms/container/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c)),
  CopyLinkSharingButton: () => import('../../components/atoms/copy-link-sharing-button/CopyLinkSharingButton.vue' /* webpackChunkName: "components/copy-link-sharing-button" */).then(c => wrapFunctional(c.default || c)),
  CopyrightLabel: () => import('../../components/atoms/copyright-label/CopyrightLabel.vue' /* webpackChunkName: "components/copyright-label" */).then(c => wrapFunctional(c.default || c)),
  FacebookSharingButton: () => import('../../components/atoms/facebook-sharing-button/FacebookSharingButton.vue' /* webpackChunkName: "components/facebook-sharing-button" */).then(c => wrapFunctional(c.default || c)),
  FilterButton: () => import('../../components/atoms/filter-button/FilterButton.vue' /* webpackChunkName: "components/filter-button" */).then(c => wrapFunctional(c.default || c)),
  FilterCounter: () => import('../../components/atoms/filter-counter/FilterCounter.vue' /* webpackChunkName: "components/filter-counter" */).then(c => wrapFunctional(c.default || c)),
  FilterItem: () => import('../../components/atoms/filter-item/FilterItem.vue' /* webpackChunkName: "components/filter-item" */).then(c => wrapFunctional(c.default || c)),
  FilterPill: () => import('../../components/atoms/filter-pill/FilterPill.vue' /* webpackChunkName: "components/filter-pill" */).then(c => wrapFunctional(c.default || c)),
  LinkedinSharingButton: () => import('../../components/atoms/linkedin-sharing-button/LinkedinSharingButton.vue' /* webpackChunkName: "components/linkedin-sharing-button" */).then(c => wrapFunctional(c.default || c)),
  MenuBar: () => import('../../components/atoms/menu-bar/MenuBar.vue' /* webpackChunkName: "components/menu-bar" */).then(c => wrapFunctional(c.default || c)),
  MenuItem: () => import('../../components/atoms/menu-bar/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c)),
  SubMenu: () => import('../../components/atoms/menu-bar/SubMenu.vue' /* webpackChunkName: "components/sub-menu" */).then(c => wrapFunctional(c.default || c)),
  SubMenuItem: () => import('../../components/atoms/menu-bar/SubMenuItem.vue' /* webpackChunkName: "components/sub-menu-item" */).then(c => wrapFunctional(c.default || c)),
  OfficeEmail: () => import('../../components/atoms/office-article-atoms/OfficeEmail.vue' /* webpackChunkName: "components/office-email" */).then(c => wrapFunctional(c.default || c)),
  OfficeOpeningTimes: () => import('../../components/atoms/office-article-atoms/OfficeOpeningTimes.vue' /* webpackChunkName: "components/office-opening-times" */).then(c => wrapFunctional(c.default || c)),
  OfficePhone: () => import('../../components/atoms/office-article-atoms/OfficePhone.vue' /* webpackChunkName: "components/office-phone" */).then(c => wrapFunctional(c.default || c)),
  Overlay: () => import('../../components/atoms/overlay/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c)),
  PardotSelectField: () => import('../../components/atoms/pardot-select-field/PardotSelectField.vue' /* webpackChunkName: "components/pardot-select-field" */).then(c => wrapFunctional(c.default || c)),
  TwitterSharingButton: () => import('../../components/atoms/twitter-sharing-button/TwitterSharingButton.vue' /* webpackChunkName: "components/twitter-sharing-button" */).then(c => wrapFunctional(c.default || c)),
  WistiaVideo: () => import('../../components/atoms/wistia-video/WistiaVideo.vue' /* webpackChunkName: "components/wistia-video" */).then(c => wrapFunctional(c.default || c)),
  BasicCard: () => import('../../components/molecules/basic-card/BasicCard.vue' /* webpackChunkName: "components/basic-card" */).then(c => wrapFunctional(c.default || c)),
  ButtonSet: () => import('../../components/molecules/button-set/ButtonSet.vue' /* webpackChunkName: "components/button-set" */).then(c => wrapFunctional(c.default || c)),
  CalloutFact: () => import('../../components/molecules/callout-fact/CalloutFact.vue' /* webpackChunkName: "components/callout-fact" */).then(c => wrapFunctional(c.default || c)),
  CalloutImage: () => import('../../components/molecules/callout-image/CalloutImage.vue' /* webpackChunkName: "components/callout-image" */).then(c => wrapFunctional(c.default || c)),
  ConclusionSummary: () => import('../../components/molecules/conclusion-summary/ConclusionSummary.vue' /* webpackChunkName: "components/conclusion-summary" */).then(c => wrapFunctional(c.default || c)),
  ContentCard: () => import('../../components/molecules/content-card/ContentCard.vue' /* webpackChunkName: "components/content-card" */).then(c => wrapFunctional(c.default || c)),
  CookieBanner: () => import('../../components/molecules/cookie-banner/CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c)),
  Drawer: () => import('../../components/molecules/drawer/Drawer.vue' /* webpackChunkName: "components/drawer" */).then(c => wrapFunctional(c.default || c)),
  NavigationDrawer: () => import('../../components/molecules/drawer/NavigationDrawer.vue' /* webpackChunkName: "components/navigation-drawer" */).then(c => wrapFunctional(c.default || c)),
  DynamicTable: () => import('../../components/molecules/dynamic-table/DynamicTable.vue' /* webpackChunkName: "components/dynamic-table" */).then(c => wrapFunctional(c.default || c)),
  FileList: () => import('../../components/molecules/file-list/FileList.vue' /* webpackChunkName: "components/file-list" */).then(c => wrapFunctional(c.default || c)),
  FilterBar: () => import('../../components/molecules/filter-bar/FilterBar.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c)),
  FilterGroup: () => import('../../components/molecules/filter-group/FilterGroup.vue' /* webpackChunkName: "components/filter-group" */).then(c => wrapFunctional(c.default || c)),
  ImageBlock: () => import('../../components/molecules/image-block/ImageBlock.vue' /* webpackChunkName: "components/image-block" */).then(c => wrapFunctional(c.default || c)),
  InfoIcon: () => import('../../components/molecules/info-icon/InfoIcon.vue' /* webpackChunkName: "components/info-icon" */).then(c => wrapFunctional(c.default || c)),
  MainNavigation: () => import('../../components/molecules/main-navigation/MainNavigation.vue' /* webpackChunkName: "components/main-navigation" */).then(c => wrapFunctional(c.default || c)),
  NavigationAccordion: () => import('../../components/molecules/main-navigation/NavigationAccordion.vue' /* webpackChunkName: "components/navigation-accordion" */).then(c => wrapFunctional(c.default || c)),
  NotificationMessage: () => import('../../components/molecules/notification-message/NotificationMessage.vue' /* webpackChunkName: "components/notification-message" */).then(c => wrapFunctional(c.default || c)),
  OfficeArticle: () => import('../../components/molecules/office-article/OfficeArticle.vue' /* webpackChunkName: "components/office-article" */).then(c => wrapFunctional(c.default || c)),
  ProfileCard: () => import('../../components/molecules/profile-card/ProfileCard.vue' /* webpackChunkName: "components/profile-card" */).then(c => wrapFunctional(c.default || c)),
  PromoPopup: () => import('../../components/molecules/promo-popup/PromoPopup.vue' /* webpackChunkName: "components/promo-popup" */).then(c => wrapFunctional(c.default || c)),
  PullQuote: () => import('../../components/molecules/pull-quote/PullQuote.vue' /* webpackChunkName: "components/pull-quote" */).then(c => wrapFunctional(c.default || c)),
  SectionHeading: () => import('../../components/molecules/section-heading/SectionHeading.vue' /* webpackChunkName: "components/section-heading" */).then(c => wrapFunctional(c.default || c)),
  SectionIntro: () => import('../../components/molecules/section-intro/SectionIntro.vue' /* webpackChunkName: "components/section-intro" */).then(c => wrapFunctional(c.default || c)),
  SocialMediaLinks: () => import('../../components/molecules/social-media-links/SocialMediaLinks.vue' /* webpackChunkName: "components/social-media-links" */).then(c => wrapFunctional(c.default || c)),
  SocialMediaStrip: () => import('../../components/molecules/social-media-strip/SocialMediaStrip.vue' /* webpackChunkName: "components/social-media-strip" */).then(c => wrapFunctional(c.default || c)),
  SolutionCardMajor: () => import('../../components/molecules/solution-card-major/SolutionCardMajor.vue' /* webpackChunkName: "components/solution-card-major" */).then(c => wrapFunctional(c.default || c)),
  SolutionCardMinor: () => import('../../components/molecules/solution-card-minor/SolutionCardMinor.vue' /* webpackChunkName: "components/solution-card-minor" */).then(c => wrapFunctional(c.default || c)),
  SubNavigation: () => import('../../components/molecules/sub-navigation/SubNavigation.vue' /* webpackChunkName: "components/sub-navigation" */).then(c => wrapFunctional(c.default || c)),
  TextBlock: () => import('../../components/molecules/text-block/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => wrapFunctional(c.default || c)),
  TwoToneTitle: () => import('../../components/molecules/two-tone-title/TwoToneTitle.vue' /* webpackChunkName: "components/two-tone-title" */).then(c => wrapFunctional(c.default || c)),
  WebinarDetailsCard: () => import('../../components/molecules/webinar-details-card/WebinarDetailsCard.vue' /* webpackChunkName: "components/webinar-details-card" */).then(c => wrapFunctional(c.default || c)),
  WebinarPresenters: () => import('../../components/molecules/webinar-presenters/WebinarPresenters.vue' /* webpackChunkName: "components/webinar-presenters" */).then(c => wrapFunctional(c.default || c)),
  AwardsBanner: () => import('../../components/organisms/awards-banner/AwardsBanner.vue' /* webpackChunkName: "components/awards-banner" */).then(c => wrapFunctional(c.default || c)),
  BlendedLearning: () => import('../../components/organisms/bleanded-learning/BlendedLearning.vue' /* webpackChunkName: "components/blended-learning" */).then(c => wrapFunctional(c.default || c)),
  ClientLogos: () => import('../../components/organisms/client-logos/ClientLogos.vue' /* webpackChunkName: "components/client-logos" */).then(c => wrapFunctional(c.default || c)),
  ContactForm: () => import('../../components/organisms/contact-form/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c)),
  ContentCards: () => import('../../components/organisms/content-cards/ContentCards.vue' /* webpackChunkName: "components/content-cards" */).then(c => wrapFunctional(c.default || c)),
  CtaModule: () => import('../../components/organisms/cta-module/CtaModule.vue' /* webpackChunkName: "components/cta-module" */).then(c => wrapFunctional(c.default || c)),
  EditorialContent: () => import('../../components/organisms/editorial-content/EditorialContent.vue' /* webpackChunkName: "components/editorial-content" */).then(c => wrapFunctional(c.default || c)),
  Error404: () => import('../../components/organisms/error-pages/Error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c)),
  Error500: () => import('../../components/organisms/error-pages/Error500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c)),
  FeaturedCaseStudy: () => import('../../components/organisms/featured-case-study/FeaturedCaseStudy.vue' /* webpackChunkName: "components/featured-case-study" */).then(c => wrapFunctional(c.default || c)),
  FeaturedContent: () => import('../../components/organisms/featured-content/FeaturedContent.vue' /* webpackChunkName: "components/featured-content" */).then(c => wrapFunctional(c.default || c)),
  FilterPanel: () => import('../../components/organisms/filter-panel/FilterPanel.vue' /* webpackChunkName: "components/filter-panel" */).then(c => wrapFunctional(c.default || c)),
  FooterDefault: () => import('../../components/organisms/footer-default/FooterDefault.vue' /* webpackChunkName: "components/footer-default" */).then(c => wrapFunctional(c.default || c)),
  FormComponent: () => import('../../components/organisms/form-component/FormComponent.vue' /* webpackChunkName: "components/form-component" */).then(c => wrapFunctional(c.default || c)),
  HeaderDefault: () => import('../../components/organisms/header-default/HeaderDefault.vue' /* webpackChunkName: "components/header-default" */).then(c => wrapFunctional(c.default || c)),
  MenuButton: () => import('../../components/organisms/header-default/MenuButton.vue' /* webpackChunkName: "components/menu-button" */).then(c => wrapFunctional(c.default || c)),
  Hero: () => import('../../components/organisms/hero/hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c)),
  HeroBanner: () => import('../../components/organisms/hero-banner/HeroBanner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c)),
  HeroBannerSolutions: () => import('../../components/organisms/hero-banner/HeroBannerSolutions.vue' /* webpackChunkName: "components/hero-banner-solutions" */).then(c => wrapFunctional(c.default || c)),
  HeroHome: () => import('../../components/organisms/hero-home/HeroHome.vue' /* webpackChunkName: "components/hero-home" */).then(c => wrapFunctional(c.default || c)),
  InfoIcons: () => import('../../components/organisms/info-icons/InfoIcons.vue' /* webpackChunkName: "components/info-icons" */).then(c => wrapFunctional(c.default || c)),
  NavigationContent: () => import('../../components/organisms/navigation-content/NavigationContent.vue' /* webpackChunkName: "components/navigation-content" */).then(c => wrapFunctional(c.default || c)),
  OfficeModule: () => import('../../components/organisms/office-module/OfficeModule.vue' /* webpackChunkName: "components/office-module" */).then(c => wrapFunctional(c.default || c)),
  QuoteBanner: () => import('../../components/organisms/quote-banner/QuoteBanner.vue' /* webpackChunkName: "components/quote-banner" */).then(c => wrapFunctional(c.default || c)),
  RelatedContent: () => import('../../components/organisms/related-content/RelatedContent.vue' /* webpackChunkName: "components/related-content" */).then(c => wrapFunctional(c.default || c)),
  ResourceActivationForm: () => import('../../components/organisms/resources-forms/ResourceActivationForm.vue' /* webpackChunkName: "components/resource-activation-form" */).then(c => wrapFunctional(c.default || c)),
  SiteBanner: () => import('../../components/organisms/site-banner/SiteBanner.vue' /* webpackChunkName: "components/site-banner" */).then(c => wrapFunctional(c.default || c)),
  SolutionsList: () => import('../../components/organisms/solutions-list/SolutionsList.vue' /* webpackChunkName: "components/solutions-list" */).then(c => wrapFunctional(c.default || c)),
  SolutionsListHighlight: () => import('../../components/organisms/solutions-list-highlight/SolutionsListHighlight.vue' /* webpackChunkName: "components/solutions-list-highlight" */).then(c => wrapFunctional(c.default || c)),
  TabbedContent: () => import('../../components/organisms/tabbed-content/TabbedContent.vue' /* webpackChunkName: "components/tabbed-content" */).then(c => wrapFunctional(c.default || c)),
  TopicsFilter: () => import('../../components/organisms/topics-filters/TopicsFilter.vue' /* webpackChunkName: "components/topics-filter" */).then(c => wrapFunctional(c.default || c)),
  TopicsFilterPanel: () => import('../../components/organisms/topics-filters/TopicsFilterPanel.vue' /* webpackChunkName: "components/topics-filter-panel" */).then(c => wrapFunctional(c.default || c)),
  ValueProp: () => import('../../components/organisms/value-prop/ValueProp.vue' /* webpackChunkName: "components/value-prop" */).then(c => wrapFunctional(c.default || c)),
  VideoBlock: () => import('../../components/organisms/video-block/VideoBlock.vue' /* webpackChunkName: "components/video-block" */).then(c => wrapFunctional(c.default || c)),
  WebinarBanner: () => import('../../components/organisms/webinar-banner/WebinarBanner.vue' /* webpackChunkName: "components/webinar-banner" */).then(c => wrapFunctional(c.default || c)),
  WhitePaperBanner: () => import('../../components/organisms/white-paper-banner/WhitePaperBanner.vue' /* webpackChunkName: "components/white-paper-banner" */).then(c => wrapFunctional(c.default || c)),
  CardSection: () => import('../../components/molecules/navigation/card-section/CardSection.vue' /* webpackChunkName: "components/card-section" */).then(c => wrapFunctional(c.default || c)),
  NavigationCard: () => import('../../components/molecules/navigation/card-section/NavigationCard.vue' /* webpackChunkName: "components/navigation-card" */).then(c => wrapFunctional(c.default || c)),
  DescriptionSection: () => import('../../components/molecules/navigation/description-section/DescriptionSection.vue' /* webpackChunkName: "components/description-section" */).then(c => wrapFunctional(c.default || c)),
  LargeIconListSection: () => import('../../components/molecules/navigation/large-icon-list-section/LargeIconListSection.vue' /* webpackChunkName: "components/large-icon-list-section" */).then(c => wrapFunctional(c.default || c)),
  NavigationLargeIconCard: () => import('../../components/molecules/navigation/large-icon-list-section/NavigationLargeIconCard.vue' /* webpackChunkName: "components/navigation-large-icon-card" */).then(c => wrapFunctional(c.default || c)),
  NavigationSmallIconCard: () => import('../../components/molecules/navigation/small-icon-list-section/NavigationSmallIconCard.vue' /* webpackChunkName: "components/navigation-small-icon-card" */).then(c => wrapFunctional(c.default || c)),
  SmallIconListSection: () => import('../../components/molecules/navigation/small-icon-list-section/SmallIconListSection.vue' /* webpackChunkName: "components/small-icon-list-section" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
