//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseHeading from '@/components/atoms/base-heading/BaseHeading.vue';
import BaseSubheading from '@/components/atoms/base-subheading/BaseSubheading.vue';

export default {
  name: 'TwoToneTitle',
  component: {
    BaseHeading,
    BaseSubheading,
  },
  props: {
    tag: {
      type: String,
      required: true,
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
      },
    },
    visualStyle: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      default: '',
    },
    subheading: {
      type: String,
      default: '',
    },
  },
};
