//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SmallIconListSection',
  props: {
    index: {
      type: Number,
      required: true,
    },
    heading: {
      type: String,
      default: undefined,
    },
    listItem: {
      type: Object,
      required: true,
    },
  },
};
