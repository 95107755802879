import { render, staticRenderFns } from "./CardSection.vue?vue&type=template&id=7967ded2&"
import script from "./CardSection.vue?vue&type=script&lang=js&"
export * from "./CardSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationCard: require('/usr/src/web-www/components/molecules/navigation/card-section/NavigationCard.vue').default,SubMenuItem: require('/usr/src/web-www/components/atoms/menu-bar/SubMenuItem.vue').default})
