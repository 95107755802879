//
//
//
//
//
//
//
//

export default {
  name: 'CopyrightLabel',
  methods: {
    getCurrentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
