import cookies from 'js-cookie';
import { initialCookiePolicy } from '~/data/cookies';

export default () => {
  const existingPolicy = cookies.get('cookie_policy');

  const cookiePolicy = existingPolicy
    ? JSON.parse(existingPolicy)
    : initialCookiePolicy;

  if (cookiePolicy?.consented && cookiePolicy?.analytics) {
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.id = 'hs-script-loader';
    scriptTag.src = '//js-eu1.hs-scripts.com/26636097.js';
    document.head.appendChild(scriptTag);
  }
};
