//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTypename } from '@/utils/navigation/navigation';

export default {
  name: 'NavigationContent',
  props: {
    section: {
      type: Object,
      required: true,
    },
    submenuIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getSectionTypeName(section) {
      return getTypename(section);
    },
    isDescriptionSection(section) {
      return (
        this.getSectionTypeName(section) ===
        'ComponentNavigationDescriptionSection'
      );
    },
    isSmallIconListSection(section) {
      return (
        this.getSectionTypeName(section) ===
        'ComponentNavigationSmallIconListSection'
      );
    },
    isLargeIconListSection(section) {
      return (
        this.getSectionTypeName(section) ===
        'ComponentNavigationLargeIconListSection'
      );
    },
    isCardSection(section) {
      return (
        this.getSectionTypeName(section) === 'ComponentNavigationCardSection'
      );
    },
    getSectionLinkIndex(section) {
      if (this.isDescriptionSection(section)) {
        return section.heading ? 2 : 1;
      }
      if (this.isSmallIconListSection(section)) {
        return section.heading
          ? section.smallIconListItems.length + 1
          : section.smallIconListItems.length;
      }
      if (this.isLargeIconListSection(section)) {
        return section.heading
          ? section.largeIconListItems.length + 1
          : section.largeIconListItems.length;
      }
    },
  },
};
