//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTypename } from '@/utils/navigation/navigation';

export default {
  name: 'NavigationAccordion',
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleAccordionOpen() {
      this.$emit('accordion-open');
    },
    handleAccordionClose() {
      this.$emit('accordion-close');
    },
    getNextSiblingClassName(sections, currentSectionIndex) {
      const nextSection = sections[currentSectionIndex + 1];
      return nextSection
        ? `next-sibling--${getTypename(nextSection).replace('Component', '')}`
        : 'no-next-sibling';
    },
    getPreviousSiblingClassName(sections, currentSectionIndex) {
      const nextSection = sections[currentSectionIndex - 1];
      return nextSection
        ? `previous-sibling--${getTypename(nextSection).replace(
            'Component',
            '',
          )}`
        : 'no-previous-sibling';
    },
  },
};
