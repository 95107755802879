//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash.debounce';
import {
  itemStateChangeEvent,
  closeAllEvent,
  translateItemEvent,
  accordionItemAttributeName,
  openAccordionItemAttributeName,
} from './Accordion.vue';

export default {
  name: 'AccordionItem',
  props: {
    headingTag: {
      type: String,
      required: true,
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
      },
    },
  },
  data() {
    return {
      isOpen: false,
      id: null,
      translate: 0,
      // hidden visibility, when item is closed so that
      // elements can't accept keyboard and mouse events
      visibility: 'hidden',
      contentTranslate: '-100%',
    };
  },
  computed: {
    cPanelId() {
      return `accordion-panel-${this.id}`;
    },
    cButtonId() {
      return `accordion-button-${this.id}`;
    },
    cDebouncedUpdateStylesWhenClosed() {
      // delaying until the content has been made invisible (opacity: 0)
      return debounce(this.updateStylesWhenClosed, 600);
    },
    cDataAttributes() {
      return {
        [accordionItemAttributeName]: this.id,
        [openAccordionItemAttributeName]: String(this.isOpen),
      };
    },
  },
  mounted() {
    this.id = this._uid;
    this.$root.$on(closeAllEvent, (openedId) => {
      if (openedId !== this.id && this.isOpen) {
        this.setIsOpen(false);
      }
    });
    this.$root.$on(translateItemEvent, (id, translateAmount) => {
      if (id !== this.id) return;

      this.translate = this.translate + translateAmount;
    });
  },
  methods: {
    handleAccordionClick() {
      // cancel any in-flight debounced calls
      this.cDebouncedUpdateStylesWhenClosed.cancel();
      this.setIsOpen(!this.isOpen);
    },
    /**
     * @param {boolean} value
     */
    setIsOpen(value) {
      this.isOpen = value;
      this.$root.$emit(
        itemStateChangeEvent,
        value,
        this.id,
        this.$refs.content.offsetHeight,
      );
      if (value) {
        this.updateStylesWhenOpened();
      } else {
        this.cDebouncedUpdateStylesWhenClosed();
      }
    },
    updateStylesWhenOpened() {
      this.visibility = 'visible';
      this.contentTranslate = '0';
    },
    updateStylesWhenClosed() {
      this.visibility = 'hidden';
      this.contentTranslate = '-100%';
    },
  },
};
