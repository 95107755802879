import { render, staticRenderFns } from "./CookieBanner.vue?vue&type=template&id=e59c4da4&scoped=true&"
import script from "./CookieBanner.vue?vue&type=script&lang=js&"
export * from "./CookieBanner.vue?vue&type=script&lang=js&"
import style0 from "./CookieBanner.vue?vue&type=style&index=0&id=e59c4da4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e59c4da4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/usr/src/web-www/components/atoms/base-button/BaseButton.vue').default})
