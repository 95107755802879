//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    tag: {
      type: String,
      required: false,
      default: 'ul',
      validator(value) {
        return ['ul', 'ol'].includes(value);
      },
    },
    listStyleIcon: {
      type: String,
      default: '',
    },
    nativeListStyle: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getFormattedIconName(iconName = null) {
      if (!iconName) return;
      return iconName.replace(/([a-z](?=[A-Z]))/g, '$1-').toLowerCase();
    },
  },
};
