var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"accordion-item",style:({
    transform: ("translateY(" + _vm.translate + "px)"),
  })},'div',_vm.cDataAttributes,false),[_c(_vm.$props.headingTag,{tag:"component",staticClass:"accordion-header"},[_c('button',{staticClass:"button-reset accordion-header__button",attrs:{"id":_vm.cButtonId,"aria-expanded":String(_vm.isOpen),"aria-controls":_vm.cPanelId,"data-cy":"accordion-item"},on:{"click":_vm.handleAccordionClick}},[_c('span',[_vm._t("title")],2),_vm._v(" "),_c('span',{staticClass:"accordion-header__button-icon"},[_c('BaseIcon',{staticClass:"close-bold",class:{
            'close-bold--open': _vm.isOpen,
          },attrs:{"aria-hidden":"true","width":"21","height":"21","name":"close-bold"}})],1)])]),_vm._v(" "),_c('div',{attrs:{"id":_vm.cPanelId,"role":"region","aria-labelledby":_vm.cButtonId}},[_c('div',{ref:"content",staticClass:"accordion-content-container",class:{
        'accordion-content-container--open': _vm.isOpen,
      },style:({
        visibility: _vm.visibility,
        transform: ("translateY(" + _vm.contentTranslate + ")"),
      })},[_c('div',{staticClass:"accordion-content-container__opacity"},[_vm._t("content")],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }