//
//
//
//
//
//
//
//
//
//
//

import BaseLink from '@/components/atoms/base-link/BaseLink.vue';

export default {
  name: 'BrandLogo',
  components: {
    BaseLink,
  },
};
