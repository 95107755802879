//
//
//
//

export default {
  name: 'Overlay',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
};
