//
//
//
//
//
//

import error404 from '~/components/organisms/error-pages/Error404.vue';
import error500 from '~/components/organisms/error-pages/Error500.vue';

export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cErrorPage() {
      if (this.error.statusCode === 404) {
        return error404;
      }
      // catch everything else
      return error500;
    },
  },
};
