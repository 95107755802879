//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Container',
  props: {
    sectionTag: {
      type: String,
      default: 'section',
      validator(tag) {
        return ['section', 'div'].includes(tag);
      },
    },
    backgroundImage: {
      type: String,
      default: '',
    },
    hasVerticalPadding: {
      type: Boolean,
      default: true,
    },
    containerTag: {
      type: String,
      default: 'div',
      validator(tag) {
        return ['article', 'div'].includes(tag);
      },
    },
    containerClass: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
};
