//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '@/mixins/global.js';
import { COOKIE_TYPES } from '~/data/cookies';

export default {
  name: 'CookieBanner',
  mixins: [global],
  methods: {
    acceptAll() {
      this.$store.dispatch('cookie/acceptAllCookies');
      this.$gtm.init('GTM-TDWP3X');
      this.createHubspotTrackingScript();
    },
    dismiss() {
      this.$store.commit('cookie/setConsent');
      this.$store.commit('cookie/setCookiePolicy', {
        [COOKIE_TYPES.NECESSARY]: true,
      });
    },
  },
};
