//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import throttle from 'lodash.throttle';
import { mapState } from 'vuex';
import siteBannerQuery from '@/apollo/queries/site-banners/query.gql';
import BaseButton from '@/components/atoms/base-button/BaseButton.vue';

export default {
  name: 'SiteBanner',
  components: {
    BaseButton,
  },
  async fetch() {
    if (this.$ssrContext?.payload?.siteBanners) {
      if (this.$ssrContext?.payload?.siteBanners?.length)
        this.$store.commit('siteBanner/setSiteBanner', {
          siteBanner: this.$ssrContext?.payload?.siteBanners[0],
        });
    } else {
      const { data } = await this.$apolloProvider.defaultClient.query({
        query: siteBannerQuery,
      });

      if (data.siteBanners?.length)
        this.$store.commit('siteBanner/setSiteBanner', {
          siteBanner: data.siteBanners[0],
        });
    }
  },
  computed: {
    ...mapState({
      siteBanner: (state) => state.siteBanner.siteBanner,
      headerHeight: (state) => state.global.headerHeight,
    }),
  },
  mounted() {
    if (this.$refs.siteBanner) {
      window.addEventListener('resize', this.handleResize());
      this.$store.commit('global/setSiteBannerHeight', {
        height: this.$refs.siteBanner.clientHeight,
      });
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize());
  },

  methods: {
    handleResize() {
      return throttle(() => {
        this.$store.commit('global/setSiteBannerHeight', {
          height: this.$refs.siteBanner.clientHeight,
        });
      }, 100);
    },
  },
};
