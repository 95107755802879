import isEqual from 'lodash.isequal';

export default function (to, from, savedPosition) {
  let yOffset = 100;
  if (window.screen.width < 1024) {
    yOffset = 80;
  }
  if (to.name === 'topics') {
    // topics page has a fixed filters header
    yOffset += 84;
  }
  if (to.hash) {
    return {
      selector: to.hash,
      offset: { x: 0, y: yOffset },
      behavior: 'smooth',
    };
  } else {
    if (to.name === 'topics' && from.name === 'topics') {
      /**
       * intention is not to scroll to the top of the page, when
       * more topics are lazy-loaded.
       * Whereas changes to the filters should scroll to the top.
       */
      if (to.query.start && to.query.start !== from.query.start) {
        return false;
      }

      if (to.query.filter && !isEqual(to.query.filter, from.query.filter)) {
        // scroll to the top of the topics results
        return {
          selector: '#topics-results',
          offset: { x: 0, y: yOffset },
          behavior: 'smooth',
        };
      }
    }
    if (from.name === 'topics-slug' && to.name === 'topics') {
      if (savedPosition) {
        return savedPosition;
      }
    }
    return { x: 0, y: 0 };
  }
}
