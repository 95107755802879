//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  getMenuLevelAttributePair,
  getMenuItemIndexAttributePair,
} from './MenuBar.vue';

export default {
  name: 'SubMenuItem',
  props: {
    menuItemTag: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    cHref() {
      return this.$props.menuItemTag === 'a' ? this.$props.href : undefined;
    },
    cTabIndex() {
      return this.$props.menuItemTag !== 'a' ? '0' : undefined;
    },
    cDataAttributes() {
      return {
        ...getMenuLevelAttributePair(2),
        ...getMenuItemIndexAttributePair(this.$props.index),
      };
    },
  },
};
