// @ts-check
import { toClassName } from '../strings/strings';

/**
 * Returns a __typename of a GraphQL element.
 * Falls back to using __component property, if
 * element was fetched via the REST API.
 *
 * @param {{ __typename: string } | { __component: string }} element
 */
export function getTypename(element) {
  return hasTypename(element)
    ? element.__typename
    : 'Component' + toClassName(element.__component);
}

/**
 * Typescript type guard to determine that a given element has
 * a __typename property on it.
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
 *
 * @param {any} element
 * @returns { element is { __typename: string } }
 */
function hasTypename(element) {
  return element.__typename;
}
