//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'Drawer',
  computed: {
    ...mapState({
      open: (state) => state.navigation.drawerOpen,
    }),
  },
  watch: {
    async open(newVal) {
      const handler = this.close;
      if (newVal) {
        this.$el.focus();
        await this.$nextTick();
        window.addEventListener('click', handler, true);
      } else {
        window.removeEventListener('click', handler, true);
      }
    },
  },
  methods: {
    close(event, override = false) {
      if (!this.$el.contains(event.target) || override) {
        event.handledByTheDrawer = true;
        this.$store.commit('navigation/toggleDrawer');
      }
    },
  },
};
