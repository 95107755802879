//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Error500',
  data: () => ({
    heroBanner: {
      title: 'Internal server error',
      subtitle: 'There is a problem with the resource you’re looking for',
      buttons: [
        {
          label: 'Try the home page',
          path: '/',
          secondary: true,
          external: false,
        },
      ],
    },
  }),
  head() {
    return {
      title: 'Internal server error | MindGym',
    };
  },
};
