import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2cf7eb2a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/usr/src/web-www/components/organisms/header-default/HeaderDefault.vue').default,SiteBanner: require('/usr/src/web-www/components/organisms/site-banner/SiteBanner.vue').default,FooterDefault: require('/usr/src/web-www/components/organisms/footer-default/FooterDefault.vue').default})
