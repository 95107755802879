import { render, staticRenderFns } from "./NavigationSmallIconCard.vue?vue&type=template&id=04e6b442&scoped=true&"
import script from "./NavigationSmallIconCard.vue?vue&type=script&lang=js&"
export * from "./NavigationSmallIconCard.vue?vue&type=script&lang=js&"
import style0 from "./NavigationSmallIconCard.vue?vue&type=style&index=0&id=04e6b442&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e6b442",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/usr/src/web-www/components/atoms/base-icon/BaseIcon.vue').default,BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default})
