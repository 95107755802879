//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavigationLargeIconCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: true,
      validator: (icon) => 'url' in icon && 'alternativeText' in icon,
    },
  },
};
