var render = function (_h,_vm) {var _c=_vm._c;return _c(_vm.props.sectionTag,{tag:"component",class:[
    'section',
    _vm.data.class,
    _vm.data.staticClass,
    !_vm.props.hasVerticalPadding ? 'section--pv-0' : '' ],style:([
    Object.assign({}, _vm.data.style),
    _vm.props.backgroundImage
      ? {
          'background-image': 'url(' + _vm.props.backgroundImage + ')',
        }
      : '' ]),attrs:{"id":_vm.props.id}},[_vm._t("pre-container"),_vm._v(" "),_c(_vm.props.containerTag,{tag:"component",class:['container', _vm.props.containerClass]},[_vm._t("default")],2),_vm._v(" "),_vm._t("post-container")],2)}
var staticRenderFns = []

export { render, staticRenderFns }