import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a769c54 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _152cd852 = () => interopDefault(import('../pages/about-us/index/index.vue' /* webpackChunkName: "pages/about-us/index/index" */))
const _5540c98f = () => interopDefault(import('../pages/about-us/index/our-coaches/index.vue' /* webpackChunkName: "pages/about-us/index/our-coaches/index" */))
const _caa921e8 = () => interopDefault(import('../pages/about-us/index/our-team/index.vue' /* webpackChunkName: "pages/about-us/index/our-team/index" */))
const _20600180 = () => interopDefault(import('../pages/case-studies/index.vue' /* webpackChunkName: "pages/case-studies/index" */))
const _5a3beb24 = () => interopDefault(import('../pages/get-in-touch/index.vue' /* webpackChunkName: "pages/get-in-touch/index" */))
const _7772d253 = () => interopDefault(import('../pages/get-in-touch-thank-you/index.vue' /* webpackChunkName: "pages/get-in-touch-thank-you/index" */))
const _5c22b5bb = () => interopDefault(import('../pages/investors/index.vue' /* webpackChunkName: "pages/investors/index" */))
const _8afe7804 = () => interopDefault(import('../pages/investors/index/index.vue' /* webpackChunkName: "pages/investors/index/index" */))
const _2d7ff912 = () => interopDefault(import('../pages/investors/index/contacts-and-advisors/index.vue' /* webpackChunkName: "pages/investors/index/contacts-and-advisors/index" */))
const _57c76797 = () => interopDefault(import('../pages/investors/index/regulatory-news/index.vue' /* webpackChunkName: "pages/investors/index/regulatory-news/index" */))
const _9d6bb8a4 = () => interopDefault(import('../pages/investors/index/reports/index.vue' /* webpackChunkName: "pages/investors/index/reports/index" */))
const _d857d326 = () => interopDefault(import('../pages/investors/index/share-information/index.vue' /* webpackChunkName: "pages/investors/index/share-information/index" */))
const _f21bb72e = () => interopDefault(import('../pages/investors/index/reports/_slug/index.vue' /* webpackChunkName: "pages/investors/index/reports/_slug/index" */))
const _6c34ea16 = () => interopDefault(import('../pages/performa/index.vue' /* webpackChunkName: "pages/performa/index" */))
const _2cf45ef6 = () => interopDefault(import('../pages/resources/index.vue' /* webpackChunkName: "pages/resources/index" */))
const _dd59c170 = () => interopDefault(import('../pages/resources/index/index.vue' /* webpackChunkName: "pages/resources/index/index" */))
const _4c4f6dfa = () => interopDefault(import('../pages/resources/index/articles/index.vue' /* webpackChunkName: "pages/resources/index/articles/index" */))
const _14e2c506 = () => interopDefault(import('../pages/resources/index/webinars/index.vue' /* webpackChunkName: "pages/resources/index/webinars/index" */))
const _46b4ecc9 = () => interopDefault(import('../pages/resources/index/whitepapers/index.vue' /* webpackChunkName: "pages/resources/index/whitepapers/index" */))
const _4bb185b5 = () => interopDefault(import('../pages/resources/index/articles/_slug/index.vue' /* webpackChunkName: "pages/resources/index/articles/_slug/index" */))
const _5a1b387e = () => interopDefault(import('../pages/resources/index/webinars/_slug/index.vue' /* webpackChunkName: "pages/resources/index/webinars/_slug/index" */))
const _3daa6d44 = () => interopDefault(import('../pages/resources/index/whitepapers/_slug/index.vue' /* webpackChunkName: "pages/resources/index/whitepapers/_slug/index" */))
const _bb36738c = () => interopDefault(import('../pages/solutions/index.vue' /* webpackChunkName: "pages/solutions/index" */))
const _47c443da = () => interopDefault(import('../pages/topics/index.vue' /* webpackChunkName: "pages/topics/index" */))
const _3f9cc8e6 = () => interopDefault(import('../pages/careers/jobs/index.vue' /* webpackChunkName: "pages/careers/jobs/index" */))
const _3de6279e = () => interopDefault(import('../pages/careers/jobs/_slug.vue' /* webpackChunkName: "pages/careers/jobs/_slug" */))
const _8f6a468a = () => interopDefault(import('../pages/case-studies/_slug/index.vue' /* webpackChunkName: "pages/case-studies/_slug/index" */))
const _58b39700 = () => interopDefault(import('../pages/people/_slug/index.vue' /* webpackChunkName: "pages/people/_slug/index" */))
const _5bf3adf5 = () => interopDefault(import('../pages/solutions/_slug/index.vue' /* webpackChunkName: "pages/solutions/_slug/index" */))
const _8e3318d6 = () => interopDefault(import('../pages/topics/_slug/index.vue' /* webpackChunkName: "pages/topics/_slug/index" */))
const _e137aba2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b375c488 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _6a769c54,
    children: [{
      path: "",
      component: _152cd852,
      name: "about-us-index"
    }, {
      path: "our-coaches",
      component: _5540c98f,
      name: "about-us-index-our-coaches"
    }, {
      path: "our-team",
      component: _caa921e8,
      name: "about-us-index-our-team"
    }]
  }, {
    path: "/case-studies",
    component: _20600180,
    name: "case-studies"
  }, {
    path: "/get-in-touch",
    component: _5a3beb24,
    name: "get-in-touch"
  }, {
    path: "/get-in-touch-thank-you",
    component: _7772d253,
    name: "get-in-touch-thank-you"
  }, {
    path: "/investors",
    component: _5c22b5bb,
    children: [{
      path: "",
      component: _8afe7804,
      name: "investors-index"
    }, {
      path: "contacts-and-advisors",
      component: _2d7ff912,
      name: "investors-index-contacts-and-advisors"
    }, {
      path: "regulatory-news",
      component: _57c76797,
      name: "investors-index-regulatory-news"
    }, {
      path: "reports",
      component: _9d6bb8a4,
      name: "investors-index-reports"
    }, {
      path: "share-information",
      component: _d857d326,
      name: "investors-index-share-information"
    }, {
      path: "reports/:slug",
      component: _f21bb72e,
      name: "investors-index-reports-slug"
    }]
  }, {
    path: "/performa",
    component: _6c34ea16,
    name: "performa"
  }, {
    path: "/resources",
    component: _2cf45ef6,
    children: [{
      path: "",
      component: _dd59c170,
      name: "resources-index"
    }, {
      path: "articles",
      component: _4c4f6dfa,
      name: "resources-index-articles"
    }, {
      path: "webinars",
      component: _14e2c506,
      name: "resources-index-webinars"
    }, {
      path: "whitepapers",
      component: _46b4ecc9,
      name: "resources-index-whitepapers"
    }, {
      path: "articles/:slug",
      component: _4bb185b5,
      name: "resources-index-articles-slug"
    }, {
      path: "webinars/:slug",
      component: _5a1b387e,
      name: "resources-index-webinars-slug"
    }, {
      path: "whitepapers/:slug",
      component: _3daa6d44,
      name: "resources-index-whitepapers-slug"
    }]
  }, {
    path: "/solutions",
    component: _bb36738c,
    name: "solutions"
  }, {
    path: "/topics",
    component: _47c443da,
    name: "topics"
  }, {
    path: "/careers/jobs",
    component: _3f9cc8e6,
    name: "careers-jobs"
  }, {
    path: "/careers/jobs/:slug",
    component: _3de6279e,
    name: "careers-jobs-slug"
  }, {
    path: "/case-studies/:slug",
    component: _8f6a468a,
    name: "case-studies-slug"
  }, {
    path: "/people/:slug",
    component: _58b39700,
    name: "people-slug"
  }, {
    path: "/solutions/:slug",
    component: _5bf3adf5,
    name: "solutions-slug"
  }, {
    path: "/topics/:slug",
    component: _8e3318d6,
    name: "topics-slug"
  }, {
    path: "/",
    component: _e137aba2,
    name: "index"
  }, {
    path: "/*",
    component: _b375c488,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
