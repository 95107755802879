//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    colour: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    iconSrc: null,
  }),

  watch: {
    name() {
      this.loadIcon();
    },
  },

  async created() {
    await this.loadIcon();
    if (this.colour) {
      this?.$el
        ?.querySelectorAll('path')
        .forEach((path) => path.setAttribute('fill', this.colour));
    }
  },

  methods: {
    async loadIcon() {
      let module = null;
      try {
        module = await import(`~/assets/icons/${this.name}.svg`);
      } catch (e) {
        console.log(this.name);
      }
      if (module) {
        this.iconSrc = module.default;
      }
    },
  },
};
