//
//
//
//
//
//
//
//
//

export default {
  name: 'MenuButton',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    drawerOpen: {
      type: Boolean,
      required: true,
    },
  },
};
