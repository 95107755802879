//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  getMenuLevelAttributePair,
  getMenuItemIndexAttributePair,
  MENU_ITEM_INDEX_ATTRIBUTE_NAME,
  getMenuItemSelector,
} from './MenuBar.vue';

export default {
  name: 'SubMenu',
  props: {
    index: {
      type: Number,
      required: true,
    },
    menuLabel: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    cDataAttributes() {
      return {
        ...getMenuLevelAttributePair(1),
        ...getMenuItemIndexAttributePair(this.$props.index),
      };
    },
  },
  methods: {
    /**
     * @param {KeyboardEvent} event
     */
    handleSubmenuKeydown(event) {
      /**
       * @type {Element}
       */
      const target = event.target;
      if (target !== this.$refs.menu) {
        return target.getAttribute('role') === 'menuitem'
          ? this.handleSubmenuItemKeydown(event)
          : undefined;
      }

      if (event.key === 'ArrowRight') {
        this.handleArrowRight();
        event.preventDefault();
      }
    },
    handleArrowRight() {
      // focus first menu item
      this.focusSubmenuItemWithIndex(0);
    },
    /**
     * @param {KeyboardEvent} event
     */
    handleSubmenuItemKeydown(event) {
      /**
       * @type {Element}
       */
      const target = event.target;
      const menuItemIndexString = target.getAttribute(
        MENU_ITEM_INDEX_ATTRIBUTE_NAME,
      );

      if (!menuItemIndexString) return;

      const menuItemIndex = Number(menuItemIndexString);

      if (event.key === 'ArrowLeft') {
        this.focusSubmenu();
        event.preventDefault();
      }
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.focusSubmenu();
        event.preventDefault();
      }
      if (event.key === 'ArrowDown') {
        this.handleSubmenuItemArrowDown(menuItemIndex);
        event.preventDefault();
      }
      if (event.key === 'ArrowUp') {
        this.handleSubmenuItemArrowUp(menuItemIndex);
        event.preventDefault();
      }
      if (event.key === 'Home') {
        this.handleSubmenuItemHome();
        event.preventDefault();
      }
      if (event.key === 'End') {
        this.handleSubmenuItemEnd();
        event.preventDefault();
      }
    },
    focusSubmenu() {
      this.$refs.menu.focus();
    },
    /**
     * @param {number} menuItemIndex
     */
    handleSubmenuItemArrowDown(menuItemIndex) {
      // move to the next menu item
      if (menuItemIndex < this.$refs.menu.children.length - 1) {
        this.focusSubmenuItemWithIndex(menuItemIndex + 1);
      }
    },
    /**
     * @param {number} menuItemIndex
     */
    handleSubmenuItemArrowUp(menuItemIndex) {
      // move to the previous menu item
      if (menuItemIndex === 0) return;

      this.focusSubmenuItemWithIndex(menuItemIndex - 1);
    },
    handleSubmenuItemHome() {
      // move to the first menu item
      this.focusSubmenuItemWithIndex(0);
    },
    handleSubmenuItemEnd() {
      // move to the last menu
      this.focusSubmenuItemWithIndex(this.$refs.menu.children.length - 1);
    },
    /**
     * @param {number} index
     */
    focusSubmenuItemWithIndex(index) {
      return this.$refs.menu
        .querySelector(getMenuItemSelector(index, 2))
        ?.focus();
    },
  },
};
