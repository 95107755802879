import { render, staticRenderFns } from "./LargeIconListSection.vue?vue&type=template&id=7043ad9c&scoped=true&"
import script from "./LargeIconListSection.vue?vue&type=script&lang=js&"
export * from "./LargeIconListSection.vue?vue&type=script&lang=js&"
import style0 from "./LargeIconListSection.vue?vue&type=style&index=0&id=7043ad9c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7043ad9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationLargeIconCard: require('/usr/src/web-www/components/molecules/navigation/large-icon-list-section/NavigationLargeIconCard.vue').default,SubMenuItem: require('/usr/src/web-www/components/atoms/menu-bar/SubMenuItem.vue').default})
