//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeroBanner',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    image: {
      type: [Object, null],
      default: () => null,
      validator: (image) =>
        'alternativeText' in image && ('url' in image || 'sourceSet' in image),
    },
    bulletIcon: {
      type: String,
      default: '',
    },
    bullets: {
      type: Array,
      default: () => [],
    },
    buttons: {
      type: Array,
      default: () => [],
      validator: (buttons) =>
        buttons.every((button) => 'label' in button && 'path' in button),
    },
    paragraph: {
      type: String,
      default: '',
    },
    metas: {
      type: Array,
      default: () => [],
    },
  },
};
