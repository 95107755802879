//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Error404',
  data: () => ({
    heroBanner: {
      title: 'Page not found',
      subtitle: 'We can’t find what you’re looking for',
      paragraph: 'Let’s get you back on track',
      buttons: [
        {
          label: 'Try the home page',
          path: '/',
          secondary: true,
          external: false,
        },
      ],
    },
  }),
  head() {
    return {
      title: 'Page not found | MindGym',
    };
  },
};
