import cookies from 'js-cookie';
import { initialCookiePolicy } from '~/data/cookies';

const existingPolicy = cookies.get('cookie_policy');
const cookiePolicy = existingPolicy
  ? JSON.parse(existingPolicy)
  : initialCookiePolicy;

export default function ({ $gtm }) {
  if (cookiePolicy?.consented && cookiePolicy?.analytics)
    $gtm.init('GTM-TDWP3X');
}
