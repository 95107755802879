//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash.debounce';
import { createPopper } from '@popperjs/core';
import {
  MENU_ITEM_INDEX_ATTRIBUTE_NAME,
  getMenuLevelAttributePair,
  getMenuItemIndexAttributePair,
  MENU_ITEM_HOVER_EVENT,
  MENU_ITEM_OPEN_EVENT,
  MENU_ITEM_CLOSE_EVENT,
  getSubMenuSelector,
  MENU_ITEM_POPPER_STYLE_CHANGE_EVENT,
} from './MenuBar.vue';

export default {
  name: 'MenuItem',
  props: {
    index: {
      type: Number,
      required: true,
    },
    submenuClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      popperInstance: null,
    };
  },
  computed: {
    cDebouncedClose() {
      // debouncing for a better UX - menu isn't closed immediately
      // when user might has accidentally left the hover area momentarily
      return debounce(this.close, 300);
    },
    cDataAttributes() {
      return {
        ...getMenuLevelAttributePair(0),
        ...getMenuItemIndexAttributePair(this.$props.index),
      };
    },
  },
  mounted() {
    this.createPopper();
    this.$root.$on(MENU_ITEM_HOVER_EVENT, (hoverItemIndex) => {
      if (hoverItemIndex !== this.$props.index) {
        this.close();
      }
    });
  },
  methods: {
    /**
     * @param {KeyboardEvent} event
     */
    handleMenuItemKeydown(event) {
      if (
        (event.key === 'ArrowDown' ||
          event.key === 'Enter' ||
          event.key === 'Space') &&
        !this.isOpen
      ) {
        this.openAndFocusFirstElement();
        event.preventDefault();
      }
      if ((event.key === 'Escape' || event.key === 'Esc') && this.isOpen) {
        this.close();
        event.preventDefault();
      }
      if (event.key === 'ArrowDown') {
        this.openAndFocusFirstElement();
        event.preventDefault();
      }
      if (event.key === 'Enter') {
        // preventing navigation / page refresh from happening
        event.preventDefault();
      }
    },
    /**
     * @param {KeyboardEvent} event
     */
    handleSubmenuKeydown(event) {
      /**
       * @type {Element}
       */
      const target = event.target;
      if (target.getAttribute('role') !== 'menu') return;

      const menuItemIndexString = target.getAttribute(
        MENU_ITEM_INDEX_ATTRIBUTE_NAME,
      );

      if (!menuItemIndexString) return;

      const menuItemIndex = Number(menuItemIndexString);

      if (event.key === 'ArrowUp') {
        this.handleSubmenuArrowUp(menuItemIndex);
        event.preventDefault();
      }

      if (event.key === 'ArrowDown') {
        this.handleSubmenuArrowDown(menuItemIndex);
        event.preventDefault();
      }

      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeAndReturnFocus();
        event.preventDefault();
      }

      if (event.key === 'Home') {
        this.handleHome();
        event.preventDefault();
      }

      if (event.key === 'End') {
        this.handleEnd();
        event.preventDefault();
      }
    },
    /**
     * @param {MouseEvent} event
     */
    handleMenuItemClick(event) {
      // prevent navigation / page refresh from happening
      event.preventDefault();
      this.open();
    },
    handleListItemMouseLeave() {
      this.cDebouncedClose();
    },
    handleListItemMouseEnter() {
      this.closeSiblingItems();
      this.open();
    },
    /**
     * @param {number} menuItemIndex
     */
    handleSubmenuArrowUp(menuItemIndex) {
      // move to the previous menu, or close, if first item
      if (menuItemIndex === 0) {
        this.closeAndReturnFocus();
      }

      this.focusSubmenuWithIndex(menuItemIndex - 1);
    },
    /**
     * @param {number} menuItemIndex
     */
    handleSubmenuArrowDown(menuItemIndex) {
      // move to the next menu
      if (menuItemIndex < this.$refs.submenu.children.length - 1) {
        this.focusSubmenuWithIndex(menuItemIndex + 1);
      }
    },
    handleHome() {
      // move to the first menu
      this.focusSubmenuWithIndex(0);
    },
    handleEnd() {
      // move to the last menu

      this.focusSubmenuWithIndex(this.$refs.submenu.children.length - 1);
    },
    createPopper() {
      this.popperInstance = createPopper(
        this.$refs.menuItem,
        this.$refs.popperContainer,
        {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 24],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 28,
              },
            },
            {
              name: 'singleton',
              enabled: true,
              phase: 'afterWrite',
              fn: this.singletonModifier,
            },
          ],
        },
      );
    },
    singletonModifier({ state }) {
      this.$root.$emit(
        MENU_ITEM_POPPER_STYLE_CHANGE_EVENT,
        this.$props.index,
        state.styles.popper,
        state.styles.arrow,
      );
    },
    open() {
      this.isOpen = true;
      // We need to tell Popper to update the tooltip position
      // after we show the tooltip, otherwise it will be incorrect
      this.popperInstance.update();

      // cancel any in-flight debounced calls to close the submenu
      this.cDebouncedClose.cancel();

      // wrapped in a nextTick, because the popperContainer size
      // will only be updated on the next tick, after UI changes
      // take effect
      this.$nextTick(function () {
        this.$root.$emit(
          MENU_ITEM_OPEN_EVENT,
          this.$props.index,
          this.$refs.popperContainer,
        );
      });
    },
    close() {
      this.isOpen = false;
      this.$root.$emit(MENU_ITEM_CLOSE_EVENT, this.$props.index);
    },
    openAndFocusFirstElement() {
      this.open();
      // wrapping in a $nextTick, as the menu isn't available immediately.
      // it will be available on the next tick, once it's been opened
      this.$nextTick(function () {
        this.$refs.submenu.querySelectorAll('[role="menu"]')[0]?.focus();
      });
    },
    closeAndReturnFocus() {
      this.$refs.menuItem.focus();
      this.close();
    },
    closeSiblingItems() {
      this.$root.$emit(MENU_ITEM_HOVER_EVENT, this.$props.index);
    },
    /**
     * @param {number} index
     */
    focusSubmenuWithIndex(index) {
      return this.$refs.submenu
        .querySelector(getSubMenuSelector(index, 1))
        ?.focus();
    },
  },
};
