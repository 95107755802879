import { render, staticRenderFns } from "./SmallIconListSection.vue?vue&type=template&id=1adc176d&scoped=true&"
import script from "./SmallIconListSection.vue?vue&type=script&lang=js&"
export * from "./SmallIconListSection.vue?vue&type=script&lang=js&"
import style0 from "./SmallIconListSection.vue?vue&type=style&index=0&id=1adc176d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1adc176d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationSmallIconCard: require('/usr/src/web-www/components/molecules/navigation/small-icon-list-section/NavigationSmallIconCard.vue').default,SubMenuItem: require('/usr/src/web-www/components/atoms/menu-bar/SubMenuItem.vue').default})
