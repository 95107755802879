import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      hubspotFormsScriptLoaded: false,
    };
  },
  computed: {
    ...mapGetters('cookie', ['analyticsEnabled']),
  },
  methods: {
    /**
     * Method to build url hierarchy for a dynamic page.
     */
    buildParentUrlPath(parent = null) {
      if (!parent) return '';

      const firstLevel = parent?.ParentPage?.Slug;
      const secondLevel = parent?.Slug;

      return firstLevel ? `${firstLevel}/${secondLevel}` : secondLevel;
    },
    firePageViewGTMEvt(
      pageType = 'informational',
      pagePath = null,
      pageTitle = '',
    ) {
      if (!pagePath) return;

      this.$gtm.push({
        route_name: 'all',
        page_type: pageType,
        page_path: pagePath,
        page_title: pageTitle,
        event: 'page_view',
      });
    },
    async pushGTMEvent(eventObject) {
      return await new Promise((resolve) => {
        if (!this.analyticsEnabled) return resolve();

        const eventTimeout = setTimeout(() => {
          resolve();
        }, 5000);

        this.$gtm.push({
          ...eventObject,
          eventCallback() {
            if (eventTimeout) window.clearTimeout(eventTimeout);
            resolve();
          },
        });
      });
    },
    createHubspotTrackingScript() {
      const scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.id = 'hs-script-loader';
      scriptTag.src = '//js-eu1.hs-scripts.com/26636097.js';
      document.head.appendChild(scriptTag);
    },
    initHubspotFormsScript() {
      if (window.hbspt?.forms) {
        this.hubspotFormsScriptLoaded = true;
      } else {
        const hubspotFormIntervalId = setInterval(() => {
          if (window.hbspt?.forms) {
            window.clearInterval(hubspotFormIntervalId);
            this.hubspotFormsScriptLoaded = true;
          }
        }, 100);
      }
    },
  },
};
