//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DescriptionSection',
  props: {
    heading: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
