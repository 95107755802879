//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NavigationCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
      validator: (image) => 'url' in image && 'alternativeText' in image,
    },
  },
};
