import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=09c71773&scoped=true&"
import script from "./Error404.vue?vue&type=script&lang=js&"
export * from "./Error404.vue?vue&type=script&lang=js&"
import style0 from "./Error404.vue?vue&type=style&index=0&id=09c71773&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c71773",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroBanner: require('/usr/src/web-www/components/organisms/hero-banner/HeroBanner.vue').default,BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default})
