import { render, staticRenderFns } from "./MainNavigation.vue?vue&type=template&id=1443ad10&scoped=true&"
import script from "./MainNavigation.vue?vue&type=script&lang=js&"
export * from "./MainNavigation.vue?vue&type=script&lang=js&"
import style0 from "./MainNavigation.vue?vue&type=style&index=0&id=1443ad10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1443ad10",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContent: require('/usr/src/web-www/components/organisms/navigation-content/NavigationContent.vue').default,MenuItem: require('/usr/src/web-www/components/atoms/menu-bar/MenuItem.vue').default,MenuBar: require('/usr/src/web-www/components/atoms/menu-bar/MenuBar.vue').default})
